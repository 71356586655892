import DeviceNewDataService from "../../services/DeviceNewDataService";

const NewDevicesModule = {
    namespaced: true,

    state: () => ({
        devices: [],
        total: 0
    }),

    mutations: {
        setDevices(state, devices) {
            state.devices = devices ? devices : [];
        },
        setTotal(state, total) {
            state.total = total
        },
        deleteDevice(state, device) {
            const devices = state.devices;
            for(let i = 0; i < devices.length; i++) {
                if (devices[i].hw_serial === device.hw_serial && devices[i].hw_serial === device.hw_serial) {
                    devices.splice(i, 1);
                    break;
                }
            }
        }
    },

    getters: {
        devices(state) {
            return state.devices
        },
        total(state) {
            return state.total
        }
    },

    actions: {
        async loadDevices(context) {
            try {
                const response = await DeviceNewDataService.getAll();
                const data = response.data.map(item=>{
                    return {
                        ... item,
                        id: item.hw_serial+'-'+item.type
                    }
                })
                context.commit('setDevices', data);
            } catch (error) {
                console.log(error);
            }
        },
        async loadTotalDevices(context) {
            try {
                const response = await DeviceNewDataService.getAllTotal();
                context.commit('setTotal', response.data.total);
            } catch (error) {
                console.log(error);
            }
        },
        async registerDevice(context, device) {
            try {
                await DeviceNewDataService.register(device.hw_serial, device.type);
                context.commit('deleteDevice', device);
            } catch (error) {
                console.log(error);
            }
        },
        async deleteDevice(context, device) {
            try {
                await DeviceNewDataService.delete(device.hw_serial, device.type);
                context.commit('deleteDevice', device);
            } catch (error) {
                console.log(error);
            }
        }
    }
};

export default NewDevicesModule;
