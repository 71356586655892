import {createVuetify} from 'vuetify'
import {aliases, fa} from 'vuetify/iconsets/fa'
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import {mdi} from 'vuetify/iconsets/mdi'
import 'vuetify/styles';
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import 'material-design-icons-iconfont/dist/material-design-icons.css' // Ensure your project is capable of handling css files
import '@fortawesome/fontawesome-free/css/all.css' // Ensure your project is capable of handling css files
import {VDateInput} from 'vuetify/labs/VDateInput'
import {VTimePicker} from 'vuetify/labs/VTimePicker'
import {VNumberInput} from 'vuetify/labs/VNumberInput'

const myCustomLightTheme = {
  dark: false,
  colors: {
    background: '#FFFFFF',
    surface: '#FFFFFF',
    'surface-bright': '#FFFFFF',
    'surface-light': '#EEEEEE',
    'surface-variant': '#424242',
    'on-surface-variant': '#EEEEEE',
    primary: '#0579b0',
    'primary-darken-1': '#034663',
    secondary: '#93a745',
    'secondary-darken-1': '#849539',
    error: '#B00020',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FB8C00',
  },
  variables: {
    'border-color': '#000000',
    'border-opacity': 0.12,
    'high-emphasis-opacity': 0.87,
    'medium-emphasis-opacity': 0.60,
    'disabled-opacity': 0.38,
    'idle-opacity': 0.04,
    'hover-opacity': 0.04,
    'focus-opacity': 0.12,
    'selected-opacity': 0.08,
    'activated-opacity': 0.12,
    'pressed-opacity': 0.12,
    'dragged-opacity': 0.08,
    'theme-kbd': '#212529',
    'theme-on-kbd': '#FFFFFF',
    'theme-code': '#F5F5F5',
    'theme-on-code': '#000000',
  }
}
export default createVuetify({
  defaults: {
    VTextField: {
      variant: 'outlined'
    },
    VSelect: {
      variant: 'outlined'
    },
    VFileInput: {
      variant: 'outlined'
    },
    VDateInput: {
      variant: 'outlined'
    },
    VAutocomplete: {
      variant: 'outlined'
    }
  },
  theme: {
    defaultTheme: 'myCustomLightTheme',
    themes: {
      myCustomLightTheme,
    },
  },
  components :{
    ...components,
    VDateInput,
    VTimePicker,
    VNumberInput
  },
  directives,
  icons: {
    defaultSet: 'fa',
    aliases,
    sets: {
      fa,
      mdi,
    },
  }
})
