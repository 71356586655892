<script setup>
import {onMounted} from 'vue';
import {useStore} from 'vuex';
import SecureStorage from '@/helpers/SecureStorage';
const store = useStore();
onMounted(() => {
  if (SecureStorage.getData()) {
    store.dispatch(`users/getUserLogin`);
  }
})
</script>

<template>
  <router-view/>
</template>

<style scoped>

</style>
