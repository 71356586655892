import Storage from 'vue-ls';
const options = {
    namespace: 'flowgen_report', // key prefix
    name: 'ls', // name variable Vue.[ls] or this.[$ls],
    storage: 'local', // storage name session, local, memory
};
const { ls } = Storage.useStorage(options)

class SecureStorage {
    getData() {
        return ls.get('dataUser');
    }

    removeData() {
        ls.remove('dataUser');
    }

    setData(data) {
        ls.set('dataUser', JSON.stringify(data), 24 * 60 * 60 * 1000);
    }
}

export default new SecureStorage()