import axios from "axios"
import SecureStorage from "@/helpers/SecureStorage";


const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL+"/api",
    headers: {
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Allow-Origin": "*"
    }
});

service.interceptors.request.use(
    config => {
        const userData = JSON.parse(SecureStorage.getData())
        if (userData && userData.token) {
            config.headers['Authorization'] = 'Bearer ' + userData.token;
        }
        return config;
    },
    error => {
        Promise.reject(error)
    });
service.interceptors.response.use(
    response => { return response; },
    error => {
        if (error.response && error.response.status=== 401) {
            // logout for expired token
            SecureStorage.removeData();
            window.location = "/";
        }else if (error.response && error.response.status === 403) {
            window.location = "/accessDenied";
        } else if (error.response && error.response.status === 404) {
          window.location = "/404/error";
        }

        return Promise.reject(error);
    }
);

export default service;
