import Route_name from '@/router/route_name';
import RoleMiddleware from '@/middleware/RoleMiddleware';

export const getRoute = () => {
  return {
    path: "/company",
    component: () => import('@/components/layouts/Company'),
    beforeEnter: RoleMiddleware,
    meta: {
      allowedRoles: ['companyadmin', 'user']
    },
    children: [
      {
        path: "reports",
        name: Route_name.COMPANY_REPORTS,
        beforeEnter: RoleMiddleware,
        component: () => import("@/pages/company/reports/List.vue")
      },
      {
        path: "reports/:id/device",
        name: Route_name.COMPANY_REPORT_DEVICES,
        beforeEnter: RoleMiddleware,
        component: () => import("@/components/devices/StandardView.vue"),
      },
      {
        path: "reports/:id/project",
        name: Route_name.COMPANY_REPORT_PROJECTS,
        beforeEnter: RoleMiddleware,
        component: () => import("@/pages/company/project/ListReport.vue")
      },
      {
        path: "users",
        name: Route_name.COMPANY_USER,
        beforeEnter: RoleMiddleware,
        component: () => import("@/pages/company/users/List"),
        meta: {
          allowedRoles: ['companyadmin']
        },
      },
      {
        path: "profile",
        name: Route_name.COMPANY_PROFILE,
        component: () => import("@/pages/company/profile/ProfileForm.vue")
      },
      {
        path: "devices",
        name: Route_name.COMPANY_DEVICES,
        component: () => import("@/pages/company/devices/List.vue")
      },
      {
        path: "tmv-devices",
        name: Route_name.COMPANY_TMV_DEVICES,
        component: () => import("@/pages/company/devices/Tmv.vue")
      },
      {
        path: "metering-devices",
        name: Route_name.COMPANY_METERING_DEVICES,
        component: () => import("@/pages/company/devices/Metering.vue")
      },
      {
        path: "devices/:id",
        name: Route_name.COMPANY_DETAIL_DEVICES,
        component: () => import("@/pages/company/devices/Detail.vue")
      },
      {
        path: "devices/:id/project",
        name: "device-project",
        component: () => import("@/pages/company/project/ListDevice.vue")
      },
      {
        path: "projects",
        children: [
          {
            path: "",
            name: Route_name.COMPANY_PROJECTS,
            component: () => import("@/pages/company/project/List.vue"),
          },
          {
            path: "create",
            name: Route_name.COMPANY_CREATE_PROJECTS,
            component: () => import("@/pages/company/project/ProjectForm.vue"),
          },
          {
            path: ":id",
            name: Route_name.COMPANY_EDIT_PROJECTS,
            component: () => import("@/pages/company/project/ProjectForm.vue"),
          },
        ]
      },
      {
        path: "dashboards",
        children: [
          {
            path: "",
            name: Route_name.COMPANY_DASHBOARDS,
            component: () => import("@/pages/company/dashboards/List.vue"),
          },
          {
            path: ":id",
            name: Route_name.COMPANY_DASHBOARD_VIEW,
            component: () => import("@/pages/company/dashboards/ViewDashboard.vue"),
          },
        ]
      },
    ]
  }
}

export default {
  getRoute
}
