const ReportModule = {
  namespaced: true,
  state: {
    reports: [],
    loading: false,
  },
  mutations: {
    setReports(state, payload) {
      state.reports = payload;
    },
    setLoading(state) {
      state.loading = !state.loading;
    }
  },
  getters: {
    getReports(state) {
      return state.reports
    },
    isLoading(state) {
      return state.loading
    },
  },
}

export default ReportModule;
