import http from "@/helpers/http-common";

class DeviceNewDataService {
  getAll() {
    return http.get("/devices_new");
  }

  getAllTotal() {
    return http.get("/devices_new/total");
  }

  register(hw_serial, type) {
    return http.post(`/devices_new/${hw_serial}/${type}/register`);
  }

  delete(hw_serial, type) {
    return http.delete(`/devices_new/${hw_serial}/${type}`);
  }

  getDeviceId(limit, offset, search) {
    return http.get("/devices_new/by-dev-id", {
      params: {
        limit: limit,
        offset: offset,
        search: search
      }
    })
  }
}

export default new DeviceNewDataService()
