import {createRouter, createWebHistory} from "vue-router";
import Route_name from "@/router/route_name";
import SecureStorage from '@/helpers/SecureStorage';
import * as Modules from "./modules";

const routes = [
  {
    path: "/",
    redirect: "/login",
    beforeEnter: (to, from, next) => {
      if (SecureStorage.getData()) {
        console.log(SecureStorage.getData())
        const role = JSON.parse(SecureStorage.getData()).data.role;
        if (role === 'superadmin') {
          next('/admin')
        }else{
          next('/company')
        }
      }else{
        return next();
      }
    },
    component: () => import('@/components/layouts/General'),
    children: [
      {
        path: '/login',
        name: Route_name.LOGIN,
        component: () => import('@/pages/Login'),
      },
    ]
  },
  Modules.SuperAdmin.getRoute(),
  Modules.Company.getRoute(),
  {
    path: "/logout",
    name: 'Logout',
    beforeEnter: (to, from, next) => {
      SecureStorage.removeData();
      next('/login'); // Redirect to login after logout
    }
  },
  {
    path: "/accessDenied",
    name: 'accessDenied',
    component: () => import('@/pages/AccessDenied'),
  },
  {
    path: "/:code/error",
    name: 'error',
    component: () => import('@/pages/NotFound'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
})

function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters);
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({...context, next: nextMiddleware});
  };
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router: router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({...context, next: nextMiddleware});
  }

  return next();
});


export default router;
