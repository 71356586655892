import SecureStorage from "@/helpers/SecureStorage";

export default function RoleMiddleware(to, from, next) {
  if (!SecureStorage.getData()) {
    next({name: 'login'});
  }
  const userData = JSON.parse(SecureStorage.getData()).data.role;
  let checker = to.meta.allowedRoles.findIndex(x => x === userData);
  if (checker > -1) {
    return next();
  } else {
    next('accessDenied');
  }
}
