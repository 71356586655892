import EndpointDataService from "../../services/EndpointDataService";

const EndpointsModule = {
    namespaced: true,

    state: () => ({
        endpoints: []
    }),

    mutations: {
        setEndpoints(state, endpoints) {
            state.endpoints = endpoints ? endpoints : [];
        },
        addEndpoint(state, endpoint) {
            state.endpoints.push(endpoint);
        },
        updateEndpoint(state, endpoint) {
            const endpoints = state.endpoints;
            for(let i = 0; i < endpoints.length; i++) {
                if (endpoints[i].id === endpoint.id) {
                    endpoints.splice(i, 1, endpoint);
                    break;
                }
            }
        },
        deleteEndpoint(state, endpoint) {
            const endpoints = state.endpoints;
            for(let i = 0; i < endpoints.length; i++) {
                if (endpoints[i].id === endpoint.id) {
                    endpoints.splice(i, 1);
                    break;
                }
            }
        }
    },

    getters: {
        endpoints(state) {
            return state.endpoints
        }
    },

    actions: {
        async load(context) {
            try {
                const response = await EndpointDataService.getAll();
                context.commit('setEndpoints', response.data);
            } catch (error) {
                console.log(error)
                //console.log(error);
            }
        },
        async update(context, model) {
            try {
                if (model.id) {
                    await EndpointDataService.update(model);
                    context.commit('updateEndpoint', model);
                } else {
                    const response = await EndpointDataService.add(model);
                    context.commit('addEndpoint', response.data);
                }
            } catch (error) {
                console.log(error);
            }
        },
        async delete(context, model) {
            try {
                await EndpointDataService.delete(model.id);
                context.commit('deleteEndpoint', model);
            } catch (error) {
                console.log(error);
            }
        }
    }
};

export default EndpointsModule;
