export const getWidthCol = (percentage) => {
  let colwidth = '';
  console.log(percentage)
  switch (percentage) {
    case "16.66":
      colwidth = "col-md-2";
      break;
    case "25":
      colwidth = "col-md-3";
      break;
    case "33.3":
      colwidth = "col-md-4";
      break;
    case "41.6":
      colwidth = "col-md-5";
      break;
    case "50":
      colwidth = "col-md-6";
      break;
    case "58.33":
      colwidth = "col-md-7";
      break;
    case "66.67":
      colwidth = "col-md-8";
      break;
    case "75":
      colwidth = "col-md-9";
      break;
    case "83.33":
      colwidth = "col-md-10";
      break;
    case "100":
      colwidth = "col-md-12";
      break;
    default:
      colwidth = "col-md-12";
  }
  return colwidth;
}

export const getNumberFromClass = (width) =>
{
  if (!width) {
    return 12;
  }
  if(isNaN(width)) {
    return width.replace(/\D/g, '');
  }else{
    return width
  }
}

