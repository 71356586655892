import http from "@/helpers/http-common";

class EndpointDataService {
  getAll() {
    return http.get("/endpoints");
  }

  get(id) {
    return http.get(`/endpoints/${id}`);
  }

  add(endpoint) {
    return http.put(`/endpoints`, endpoint);
  }

  update(endpoint) {
    return http.post(`/endpoints/${endpoint.id}`, endpoint);
  }

  delete(id) {
    return http.delete(`/endpoints/${id}`);
  }
}

export default new EndpointDataService()