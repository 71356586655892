import Route_name from '@/router/route_name';
import RoleMiddleware from '@/middleware/RoleMiddleware';

export const getRoute = () => {
  return {
    path: "/admin",
    component: () => import('@/components/layouts/SuperAdmin'),
    beforeEnter: RoleMiddleware,
    meta: {
      allowedRoles: ['superadmin']
    },
    children: [
      {
        path: "new-devices",
        name: Route_name.NEW_DEVICES,
        component: () => import("@/pages/admin/new_devices/List"),
      },
      {
        path: "hydraloop",
        name: Route_name.HYDRALOOP,
        component: () => import("@/pages/admin/hydraloop/List"),
      },
      {
        path: "endpoints",
        name: Route_name.ENDPOINTS,
        component: () => import("@/pages/admin/endpoints/List"),
      },
      {
        path: "users",
        name: Route_name.USERS,
        component: () => import("@/pages/admin/users/List"),
      },
      {
        path: "companies",
        name: Route_name.COMPANIES,
        component: () => import("@/pages/admin/companies/List"),
      },
      {
        path: "api-keys",
        name: Route_name.API_KEYS,
        component: () => import("@/pages/admin/api_keys/List"),
      },
      {
        path: "devices",
        name: Route_name.DEVICES,
        component: () => import("@/pages/admin/devices/List"),
      },
      {
        path: "tmv",
        name: Route_name.TMV,
        component: () => import("@/pages/admin/devices/Tmv"),
      },
      {
        path: "metering",
        name: Route_name.METERING,
        component: () => import("@/pages/admin/devices/Metering"),
      },
      {
        path: "devices/:id",
        name: Route_name.DEVICE_DETAILS,
        component: () => import("@/pages/admin/devices/Detail"),
      },
      {
        path: "reports",
        name: Route_name.REPORTS,
        component: () => import("@/pages/admin/reports/List"),
      },
      {
        path: "reports/:id/devices",
        name: Route_name.REPORTS_DEVICES,
        component: () => import("@/components/devices/StandardView.vue"),
      },
      {
        path: "reports/:id/tmv",
        name: Route_name.REPORTS_TMV,
        component: () => import("@/pages/admin/reports/Tmv"),
      },
      {
        path: "dashboards",
        children: [
          {
            path: "",
            name: Route_name.DASHBOARD_LIST,
            component: () => import("@/pages/admin/dashboards/List"),
          },
          {
            path: "create",
            name: Route_name.DASHBOARD_CREATE,
            component: () => import("@/pages/admin/dashboards/CreateDashboard"),
          },
          {
            path: ":id/edit",
            name: Route_name.DASHBOARD_EDIT,
            component: () => import("@/pages/admin/dashboards/EdiDashboard"),
          },
          {
            path: ":id/show",
            name: Route_name.DASHBOARD_VIEW,
            component: () => import("@/pages/admin/dashboards/ViewDashboard"),
          },
        ]
      },
    ]
  }
}

export default {
  getRoute
}
