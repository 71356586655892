import {createApp} from 'vue'
import HighchartsVue from 'highcharts-vue';
// Components
import App from './App.vue'
import Highcharts from 'highcharts'
import stock from "highcharts/modules/stock";
import loadHighchartsMore from "highcharts/highcharts-more.js";
import loadBullet from "highcharts/modules/bullet";
import loadSolidGauge from "highcharts/modules/solid-gauge";
import ToastPlugin from 'vue-toast-notification';
// Import the CSS or use your own!
import 'vue-toast-notification/dist/theme-default.css';
import router from "@/router";
import store from "@/store";
import vuetify  from "@/plugins/vuetify";

stock(Highcharts)
loadHighchartsMore(Highcharts);
loadSolidGauge(Highcharts);
loadBullet(Highcharts);

createApp(App)
.use(router)
.use(store)
.use(vuetify)
.use(ToastPlugin, {
  position: 'top-right'
})
.use(HighchartsVue, {
  highcharts: Highcharts
})
.mount('#app')
