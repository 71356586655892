const LOGIN = "LOGIN"
const NEW_DEVICES = 'NEW_DEVICES'
const HYDRALOOP = 'HYDRALOOP'
const ENDPOINTS = 'ENDPOINTS'
const USERS = 'USERS'
const COMPANIES = 'COMPANIES'
const API_KEYS = 'API_KEYS'
const DEVICES = 'DEVICES'
const TMV = 'TMV'
const METERING = 'METERING'
const DEVICE_DETAILS = 'DEVICE_DETAILS'
const REPORTS = 'REPORTS'
const REPORTS_DEVICES = 'REPORTS_DEVICES'
const REPORTS_TMV = 'REPORTS_TMV'
const DASHBOARD_LIST = 'DASHBOARD_LIST'
const DASHBOARD_CREATE = 'DASHBOARD_CREATE'
const DASHBOARD_EDIT = 'DASHBOARD_EDIT'
const DASHBOARD_VIEW = 'DASHBOARD_VIEW'

// for company
const COMPANY_USER = 'COMPANY_USER'
const COMPANY_PROFILE = 'COMPANY_PROFILE'
const COMPANY_DEVICES = 'COMPANY_DEVICES'
const COMPANY_TMV_DEVICES = 'COMPANY_TMV_DEVICES'
const COMPANY_METERING_DEVICES = 'COMPANY_METERING_DEVICES'
const COMPANY_DETAIL_DEVICES = 'COMPANY_DETAIL_DEVICES'
const COMPANY_PROJECTS = 'COMPANY_PROJECTS'
const COMPANY_CREATE_PROJECTS = 'COMPANY_CREATE_PROJECTS'
const COMPANY_EDIT_PROJECTS = 'COMPANY_EDIT_PROJECTS'
const COMPANY_REPORTS = 'COMPANY_REPORTS'
const COMPANY_REPORT_DEVICES = 'COMPANY_REPORT_DEVICES'
const COMPANY_DASHBOARDS = 'COMPANY_DASHBOARDS'
const COMPANY_DASHBOARD_VIEW = 'COMPANY_DASHBOARD_VIEW'
const COMPANY_REPORT_PROJECTS = 'COMPANY_REPORT_PROJECTS'

export default {
  LOGIN,
  NEW_DEVICES,
  HYDRALOOP,
  ENDPOINTS,
  USERS,
  COMPANIES,
  DEVICES,
  API_KEYS,
  TMV,
  DEVICE_DETAILS,
  METERING,
  REPORTS,
  REPORTS_DEVICES,
  REPORTS_TMV,
  DASHBOARD_LIST,
  DASHBOARD_CREATE,
  DASHBOARD_EDIT,
  DASHBOARD_VIEW,
  COMPANY_USER,
  COMPANY_PROFILE,
  COMPANY_DEVICES,
  COMPANY_TMV_DEVICES,
  COMPANY_METERING_DEVICES,
  COMPANY_DETAIL_DEVICES,
  COMPANY_PROJECTS,
  COMPANY_CREATE_PROJECTS,
  COMPANY_EDIT_PROJECTS,
  COMPANY_REPORTS,
  COMPANY_REPORT_DEVICES,
  COMPANY_DASHBOARD_VIEW,
  COMPANY_DASHBOARDS,
  COMPANY_REPORT_PROJECTS
}
