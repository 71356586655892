import http from "@/helpers/http-common";

const UserModule = {
    namespaced: true,

    state: () => ({
        user: {
            role: "user"
        }
    }),

    mutations: {
        setUser(state, user) {
            state.user = user
        }
    },

    getters: {
        user(state) {
            return state.user
        }
    },

    actions: {
        getUserLogin(context) {
            http.get("/auth/me").then(({data})=>{
                context.commit('setUser', data)
            }).catch(error=>{
                console.log(error.response)
                console.log(error);
            });
        }
    }
}

export default UserModule
