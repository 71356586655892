import HydraloopDataService from "../../services/HydraloopDataService";

const HydraloopModule = {
    namespaced: true,

    state: () => ({
        endpoints: []
    }),

    mutations: {
        setHydraloop(state, endpoints) {
            state.endpoints = endpoints ? endpoints : [];
        },
        addHydraloop(state, endpoint) {
            state.endpoints.push(endpoint);
        },
        updateHydraloop(state, endpoint) {
            const endpoints = state.endpoints;
            for(let i = 0; i < endpoints.length; i++) {
                if (endpoints[i].id === endpoint.id) {
                    endpoints.splice(i, 1, endpoint);
                    break;
                }
            }
        },
        deleteHydraloop(state, endpoint) {
            const endpoints = state.endpoints;
            for(let i = 0; i < endpoints.length; i++) {
                if (endpoints[i].id === endpoint.id) {
                    endpoints.splice(i, 1);
                    break;
                }
            }
        }
    },

    getters: {
        hydraloop(state) {
            return state.endpoints
        }
    },

    actions: {
        async load(context) {
            try {
                const response = await HydraloopDataService.getAll();
                context.commit('setHydraloop', response.data);
            } catch (error) {
                console.log(error);
            }
        },
        async update(context, model) {
            try {
                if (model.id) {
                    await HydraloopDataService.update(model);
                    context.commit('updateHydraloop', model);
                } else {
                    const response = await HydraloopDataService.add(model);
                    context.commit('addHydraloop', response.data);
                }
            } catch (error) {
                console.log(error);
            }
        },
        async delete(context, model) {
            try {
                await HydraloopDataService.delete(model.id);
                context.commit('deleteHydraloop', model);
            } catch (error) {
                console.log(error);
            }
        }
    }
};

export default HydraloopModule;
