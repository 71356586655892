import http from "@/helpers/http-common";

class HydraloopDataService {
  getAll() {
    return http.get("/hydraloop");
  }

  get(id) {
    return http.get(`/hydraloop/${id}`);
  }

  add(endpoint) {
    return http.put(`/hydraloop`, endpoint);
  }

  update(endpoint) {
    return http.post(`/hydraloop/${endpoint.id}`, endpoint);
  }

  delete(id) {
    return http.delete(`/hydraloop/${id}`);
  }
}

export default new HydraloopDataService()
