class ErrorsHelper {
  extractErrorMessage(error) {
    let message = "";
    if (error) {
      if (error.response) {
        const messageArr = error.response.data.errors;
        if (messageArr) {
          message = messageArr.map(m=> {
            return m.msg;
          }).join(", ")
        }else{
          message = error.response.data
              ? error.response.data.message
                  ? error.response.data.message
                  : error.response.data
              : "";
        }

      }
      if (!message) {
        message = error.message ? error.message : error;
      }
    }
    return message ? message : "Unknown error";
  }
}

export default new ErrorsHelper()