import httpCommon from "@/helpers/http-common";
import ErrorsHelper from "@/helpers/ErrorsHelper";
import { getWidthCol } from "@/helpers/Dashboard";
import jsonData from "@/assets/city.list.json";

function base64ToFile(base64String, fileName) {
  // Split the base64 string into content and metadata parts
  const [metadata, content] = base64String.split(',');

  // Extract the MIME type from the metadata
  const mimeType = metadata.match(/:(.*?);/)[1];

  // Convert the base64 content to binary data
  const binary = atob(content);

  // Create a Uint8Array from the binary data
  const arrayBuffer = new Uint8Array(binary.length);
  for (let i = 0; i < binary.length; i++) {
    arrayBuffer[i] = binary.charCodeAt(i);
  }

  // Create a file or blob
  const file = new File([arrayBuffer], fileName, {type: mimeType});

  return file;
}

const DashboardModule = {
  namespaced: true,
  state: () => ({
    selectedDashboard: {
      users: [],
      projects: [],
      companies: [],
      id: null,
      logo: null,
      rows: [],
      city: null,
      city_name: null,
      old_logo: null,
    },
    activeRowIdx: undefined,
    activeColIdx: undefined,
    loading: false,
    error: null,
    isUploadImage: false,
    isUpdate: false,
    ids: new Set(),
    websocket: null,
    socketError: false,
    socketConnected: false,
  }),
  mutations: {
    setIsUploadImage(state, val) {
      state.isUploadImage = val;
    },
    setIsUpdate(state, val) {
      state.isUpdate = val;
    },
    setWebSocket(state, websocket) {
      state.websocket = websocket;
    },
    setSocketConnected(state, isConnected) {
      state.socketConnected = isConnected;
    },
    setSocketError(state, error) {
      state.socketError = error;
    },
    getWebSocketStatus(state) {
      return {
        connected: state.socketConnected,
        error: state.socketError,
      };
    },
    // Mutation to add an id to the ids array if it doesn't exist
    addIdsIfNotExists(state, ids) {
      if (!ids) return;
      // Convert to Set if it's an array
      const idsSet = Array.isArray(ids) ? new Set(ids) : ids;
      // Ensure idsSet is a Set, then add to state.ids
      if (idsSet instanceof Set) {
        idsSet.forEach((id) => state.ids.add(id)); // Adds unique IDs
      } else {
        console.error("Expected ids to be a Set or an array");
      }
    },
    addRectCreateElementData(state, { rowIndex, colIndex, eleIndex, rect, isValid, viewPort }) {
      const requiredElement = state.selectedDashboard.rows[rowIndex][colIndex].elements[eleIndex];
      if (!requiredElement) return; // Early return if element doesn't exist

      // Update rects and dimensions
      if (requiredElement.rects == null || requiredElement.rects === undefined) {
        requiredElement.rects = [rect];
        requiredElement.width = viewPort.baseVal.width; // Use viewPort passed from the action
        requiredElement.height = viewPort.baseVal.height;
      } else {
        if (!requiredElement.width) {
          requiredElement.width = viewPort.baseVal.width;
          requiredElement.height = viewPort.baseVal.height;
        }
        requiredElement.rects.push(rect);
      }

      // Update xidwithValues
      if (requiredElement.xidwithValues == null || requiredElement.xidwithValues === undefined) {
        requiredElement.xidwithValues = [{
          'xid': rect.xid, // Ensure XidValue is defined in the context
          'end': isValid.data[0].end,
          'dev_id': isValid.data[0].dev_id,
          'type': isValid.data[0].type,
          'id': isValid.data[0].id
        }];
      } else {
        const xidIndex = requiredElement.xidwithValues.findIndex(x => x.xid === rect.xid);
        if (xidIndex === -1) {
          requiredElement.xidwithValues.push({
            'xid': rect.xid,
            'end': isValid.data[0].end,
            'dev_id': isValid.data[0].dev_id,
            'type': isValid.data[0].type,
            'id': isValid.data[0].id
          });
        } else {
          requiredElement.xidwithValues[xidIndex] = {
            'xid': rect.xid,
            'end': isValid.data[0].end,
            'dev_id': isValid.data[0].dev_id,
            'type': isValid.data[0].type,
            'id': isValid.data[0].id
          };
        }
      }
    },
    stateDataPointElementData(state, { rowIndex, colIndex, eleIndex, newXidValue, isVisible, selectedOnColor, selectedOffColor, dev_id, device_id, currentElementId, flashEnabled, flashState, verticalRotate, rotateState,spinFan, spinState }) {
      const elementData = state.selectedDashboard.rows[rowIndex][colIndex].elements[eleIndex];

      // Ensure stateDatapoints is initialized
      if (!elementData.stateDatapoints) {
        elementData.stateDatapoints = [];
      }

      // Create the new object to be added/updated
      const obj = {
        id: currentElementId,
        dataPoint: newXidValue,
        dev_id: dev_id,
        device_id: device_id,
        onColor: selectedOnColor,
        offColor: selectedOffColor,
        flashEnabled,
        flashState,
        verticalRotate,
        rotateState,
        spinFan,
        spinState
      };
      // Check if the device_id already exists in the ids Set and add it if not
      if (!state.ids.has(device_id)) {
        state.ids.add(device_id);
      }

      // Find index of existing stateDatapoint or add new one
      let count = elementData.stateDatapoints.findIndex((x) => x.id === currentElementId);
      if (count < 0) {
        elementData.stateDatapoints.push(obj);
      } else {
        elementData.stateDatapoints[count] = obj;
      }

      // Update xidwithValues if necessary
      if (!elementData.xidwithValues) {
        elementData.xidwithValues = [];
      }

      let xidEle = elementData.xidwithValues.find(x => x.xid === newXidValue);
      if (!xidEle) {
        elementData.xidwithValues.push({
          xid: newXidValue,
          end: isVisible ? 1 : 0,
          dev_id: dev_id,
          id: device_id
        });
      }
    },
    updateDashboardWithSocketData(state,latestReading)
    {
      if (state.ids.has(latestReading.id)) {
        const end = Number(latestReading.end);
        state.selectedDashboard.rows.forEach(row => {
          row.forEach(col => {
            col.elements.forEach(ele => {
              if (ele.type === 'svg') {
                const data = ele?.xidwithValues?.find(x => x.id === latestReading.id);
                if (data) {
                  ele.checkId = latestReading.id;
                  data.end = end;
                }
              }
            });
          });
        });
      }
    },
    deleteRectElementData(state,{rowIndex, colIndex, eleIndex, rectIndex}){
      const requiredElement = state.selectedDashboard.rows[rowIndex][colIndex].elements[eleIndex];
      if (!requiredElement || requiredElement.rects.length === 0) {
        return;
      }
      requiredElement.rects.splice(rectIndex, 1);
    },
    //UpserXidWithValues
    upsertXidWithValues(state, { rowIndex, colIndex,eleIndex, newXidValue, isValid }) {
      const element = state.selectedDashboard.rows[rowIndex][colIndex].elements[eleIndex];
      if (!element) return; // Early return if element doesn't exist

      const xidIndex = element.xidwithValues.findIndex(x => x.xid === newXidValue);
      const newValue = {
        'xid': newXidValue,
        'end': isValid.data[0].end,
        'dev_id': isValid.data[0].dev_id,
        'type': isValid.data[0].type,
        'id': isValid.data[0].id,
      };

      if (xidIndex !== -1) {
        // If xid already exists, update the existing entry
        element.xidwithValues[xidIndex] = newValue;
      } else {
        // If xid does not exist, push the new entry
        element.xidwithValues.push(newValue);
        state.ids.add(isValid.data[0].id);
      }
    },
    // Update xidwithValues for an element
    updateXidWithValues(state, { rowIndex, colIndex, elIndex, xidwithValues }) {
      const element =
        state.selectedDashboard?.rows?.[rowIndex]?.[colIndex]?.elements?.[elIndex] ?? null;
      if (element) {
        // Update the element's xidwithValues property
        element.xidwithValues = xidwithValues;
      }
    },
    updateXidRectData(state, { rowIndex, colIndex, elIndex, rectIndex, x, y }) {
      const element =
        state.selectedDashboard.rows[rowIndex][colIndex].elements[elIndex]
          .rects[rectIndex];
      if (element) {
        element.x = x;
        element.y = y;
      }
    },
    // Mutation to update the element's data with serialized SVG string
    updateElementData(state, { rowIndex, colIndex, elIndex, serializedData }) {
      const element =
      state.selectedDashboard.rows[rowIndex][colIndex].elements[elIndex];
      if (element) {
        element.data = serializedData;
      }
    },
    setSelectedDashboard(state, dashboard) {
      let data = {};
      if (dashboard.data) {
        data = JSON.parse(dashboard.data)
      }

      // if (Object.prototype.hasOwnProperty.call(state.selectedDashboard, key))
      const cityName = Object.prototype.hasOwnProperty.call(data, 'city') ? data['city'] : null
      let city = dashboard.city
      if (cityName) {
        const selectedCity = jsonData.find(c => c.name === cityName)
        if(selectedCity) {
          city = selectedCity.id;
        }
      }
      state.ids = new Set();
      state.selectedDashboard = {
        ...state.selectedDashboard,
        ...dashboard,
        rows: data['rows'] || [],
        city_name: cityName,
        old_logo: Object.prototype.hasOwnProperty.call(data, 'logo') ? data['logo'] : null,
        city: city
      };
    },
    setSelectedDashboardKey(state, {key, value}) {
      if (Object.prototype.hasOwnProperty.call(state.selectedDashboard, key)) {
        if (Array.isArray(state.selectedDashboard[key])) {
          state.selectedDashboard[key].push(value);
        }else{
          state.selectedDashboard[key] = value;
        }
      }
    },
    setActiveRowIdx(state, idx) {
      state.activeRowIdx = idx;
    },
    setActiveColIdx(state, idx) {
      state.activeColIdx = idx;
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
    setError(state, error) {
      state.error = error;
    },
    addRow(state, rowIndex) {
      state.selectedDashboard['rows'].splice(rowIndex + 1, 0, []);
    },
    deleteRow(state, rowIndex) {
      state.selectedDashboard['rows'].splice(rowIndex,1);
    },
    changeColumWidth(state, {rowIdx, colIdx, percentage}) {
      state.selectedDashboard['rows'][rowIdx][colIdx] = {
        ...state.selectedDashboard['rows'][rowIdx][colIdx],
        'colwidth': getWidthCol(percentage.toString()),
        'widthPercent': percentage.toString(),
      }
    },
    addColumn(state, {index, percentage}) {
      state.selectedDashboard['rows'][index].push({
        'colwidth': getWidthCol(percentage.toString()),
        'widthPercent': percentage.toString(),
        "elements": [],
        'display': true
      })
    },
    deleteColumn(state, {rowIdx, colIdx}) {
      state.selectedDashboard['rows'][rowIdx].splice(colIdx, 1);
    },
    addElement(state, data) {
      state.selectedDashboard['rows'][state.activeRowIdx][state.activeColIdx].elements.push(data)
    },
    deleteElement(state, {rowIdx, colIdx, elIdx}) {
      state.selectedDashboard['rows'][rowIdx][colIdx].elements.splice(elIdx, 1);
    },
    deleteNote(state, {rowIdx, colIdx, elIdx, noteIdx}) {
      state.selectedDashboard['rows'][rowIdx][colIdx].elements[elIdx].data.splice(noteIdx, 1);
    },
    updateNote(state, {rowIdx, colIdx, elIdx, noteIdx, note}) {
      state.selectedDashboard['rows'][rowIdx][colIdx].elements[elIdx].data[noteIdx]=note;
    },
    appendNote(state, {rowIdx, colIdx, elIdx, note}) {
      state.selectedDashboard['rows'][rowIdx][colIdx].elements[elIdx].data.push(note);
    },
    updateElement(state, {rowIdx, colIdx, elIdx, data}) {
      state.selectedDashboard['rows'][rowIdx][colIdx].elements[elIdx]=data;
    }
  },
  getters: {
    getSelectedDashboard(state) {
      return state.selectedDashboard
    },
    getLoading(state) {
      return state.loading
    },
    getIsUploadImage(state) {
      return state.isUploadImage
    }
  },
  actions: {
    // setupWebSocket action
    setupWebSocket({ commit, state, dispatch }) {
      console.log('web socket')
      if (state.websocket) return;  // Don't create multiple connections

      const socket = new WebSocket(process.env.VUE_APP_WEBSOCKET);
      commit('setWebSocket', socket);

      socket.onopen = () => {
        commit('setSocketConnected', true);
        console.log('WebSocket connection established');
      };

      socket.onmessage = (event) => {
        // Handle incoming data
        if (event.data !== 'keep connection') {
          try {
            const latestReading = JSON.parse(event.data);
            // Update the dashboard with the new data
            commit('updateDashboardWithSocketData', latestReading);
          } catch (e) {
            console.error('Error parsing WebSocket data:', e);
          }
        }
      };

      socket.onerror = (error) => {
        commit('setSocketError', true);
        console.error('WebSocket error: ', error);
      };

      socket.onclose = (event) => {
        commit('setSocketConnected', false);
        console.log('WebSocket connection closed', event);

        // Optionally, attempt to reconnect after a delay
        setTimeout(() => {
          commit('setSocketError', false);
          dispatch('setupWebSocket'); // Recursively attempt to reconnect
        }, 1000);
      };
    },

    // Action to close the WebSocket connection
    closeWebSocket({ state, commit }) {
      if (state.websocket) {
        state.websocket.close();
        commit('setWebSocket', null);  // Reset the WebSocket state
        commit('setSocketConnected', false);  // Mark as disconnected
      }
    },
    uploadImage(context, data) {
      context.commit('setIsUploadImage', true);

      var formData = new FormData();
      formData.append('image', data.file);
      formData.append('type', data.type);
      return new Promise((resolve, reject) => {
        httpCommon.post(`dashboard-v2/${context.state.selectedDashboard.id}/add-image`, formData).then(({data}) => {
          resolve(data)
        }).catch(err => {
          reject(err)
        }).finally(() => {
          context.commit('setIsUploadImage', false);
        })
      })
    },
    addIdsIfNotExists({ commit }, ids) {
      commit("addIdsIfNotExists", ids);
    },
    addRectCreateElementData({ commit }, { rowIndex, colIndex, eleIndex, rect, isValid, viewPort }) {
       commit('addRectCreateElementData', { rowIndex, colIndex, eleIndex, rect, isValid, viewPort });
    },
    stateDataPointElementDataAction({ commit }, { rowIndex, colIndex, eleIndex, newXidValue, isVisible, selectedOnColor, selectedOffColor, dev_id, device_id, currentElementId,flashEnabled, flashState, verticalRotate, rotateState, spinFan, spinState}) {
      commit('stateDataPointElementData', { rowIndex, colIndex, eleIndex, newXidValue, isVisible, selectedOnColor, selectedOffColor, dev_id, device_id, currentElementId, flashEnabled, flashState, verticalRotate, rotateState,spinFan, spinState });
    },
    deleteRectElementData({ commit }, { rowIndex, colIndex, eleIndex, rectIndex }) {
      commit('deleteRectElementData', { rowIndex, colIndex, eleIndex, rectIndex });
    },
    upsertXidWithValues({ commit }, { rowIndex, colIndex,eleIndex, newXidValue, isValid }) {
      commit('upsertXidWithValues', { rowIndex, colIndex, eleIndex, newXidValue, isValid });
    },

    // Action to update xidwithValues
    updateElementXidWithValues(
      { commit },
      { rowIndex, colIndex, elIndex, xidwithValues }
    ) {
      commit("updateXidWithValues", {
        rowIndex,
        colIndex,
        elIndex,
        xidwithValues,
      });
    },
    updateXidRectData({ commit }, payload) {
      commit("updateXidRectData", payload);
    },
    // Action to update the element's data with serialized SVG string
    updateSerializedData(
      { commit },
      { rowIndex, colIndex, elIndex, serializedData }
    ) {
      commit("updateElementData", {
        rowIndex,
        colIndex,
        elIndex,
        serializedData,
      });
    },
    // Action to add an id to the ids array if it doesn't exist
    addIdToStore({ commit }, id) {
      commit("addIdIfNotExists", id);
    },
    loadById(context, id) {
      context.commit('setLoading', true);
      httpCommon.get(`dashboard-v2/${id}`).then(({data}) => {
        context.commit('setSelectedDashboard', data);
      }).catch(err => {
        context.commit('setError', ErrorsHelper.extractErrorMessage(err));
      }).finally(() => {
        context.commit('setLoading', false);
      })
    },
    attachUser(context, userId) {
      httpCommon.put(`dashboard-v2/${context.state.selectedDashboard.id}/attach-user`, {
        user_id: userId
      }).then(({data}) => {
        context.commit('setSelectedDashboard', data);
      }).catch(err => {
        context.commit('setError', ErrorsHelper.extractErrorMessage(err));
      }).finally(() => {

      })
    },
    attachCompany(context, companyId) {
      httpCommon.put(`dashboard-v2/${context.state.selectedDashboard.id}/attach-company`, {
        company_id: companyId
      }).then(({data}) => {
        context.commit('setSelectedDashboard', data);
      }).catch(err => {
        context.commit('setError', ErrorsHelper.extractErrorMessage(err));
      }).finally(() => {

      })
    },
    attachProject(context, companyId) {
      httpCommon.put(`dashboard-v2/${context.state.selectedDashboard.id}/attach-project`, {
        project_id: companyId
      }).then(({data}) => {
        context.commit('setSelectedDashboard', data);
      }).catch(err => {
        context.commit('setError', ErrorsHelper.extractErrorMessage(err));
      }).finally(() => {

      })
    },

    detachUser(context, userId) {
      httpCommon.put(`dashboard-v2/${context.state.selectedDashboard.id}/detach-user`, {
        user_id: userId
      }).then(({data}) => {
        context.commit('setSelectedDashboard', data);
      }).catch(err => {
        context.commit('setError', ErrorsHelper.extractErrorMessage(err));
      }).finally(() => {

      })
    },

    detachCompany(context, companyId) {
      httpCommon.put(`dashboard-v2/${context.state.selectedDashboard.id}/detach-company`, {
        company_id: companyId
      }).then(({data}) => {
        context.commit('setSelectedDashboard', data);
      }).catch(err => {
        context.commit('setError', ErrorsHelper.extractErrorMessage(err));
      }).finally(() => {

      })
    },

    detachProject(context, projectId) {
      httpCommon.put(`dashboard-v2/${context.state.selectedDashboard.id}/detach-project`, {
        project_id: projectId
      }).then(({data}) => {
        context.commit('setSelectedDashboard', data);
      }).catch(err => {
        context.commit('setError', ErrorsHelper.extractErrorMessage(err));
      }).finally(() => {

      })
    },
    updateDashboard(context) {
      var formData = new FormData();
      const dashboard = JSON.parse(JSON.stringify(context.state.selectedDashboard));
      if(dashboard.logo) {
        formData.append('logo', dashboard.logo);
      }else{
        console.log(dashboard.old_logo)
        if (dashboard.old_logo && dashboard.old_logo.data) {
          const fileName = "image.png";
          const file = base64ToFile(dashboard.old_logo.data, fileName);
          formData.append('logo', file);
        }
      }
      formData.append("title", dashboard.dashBoardName);
      formData.append("city", dashboard.city);
       dashboard.rows.forEach(row =>
        row.forEach(column =>
          column.elements?.forEach(element => {
            if (element.type === 'svg' ) {
              const { checkId, reference } = element;
              if (checkId) delete element.checkId;
              if (reference?.path) delete element.data;
            }
          })
        )
      );
      formData.append("data", JSON.stringify({
        'rows': dashboard.rows
      }));
      context.commit('setIsUpdate', true);
      return new Promise((resolve, reject) => {
        httpCommon.put(`dashboard-v2/${context.state.selectedDashboard.id}`, formData).then(() => {
          resolve(200)
        }).catch(err => {
          reject(err)
        }).finally(() => {
          context.commit('setIsUpdate', false);
        })
      })
    }
  },
}

export default DashboardModule;
